export default [
  {
    _id: "64e6b5528d2e0b0f33f679ec",
    judul: "Surat Keterangan Lulus Uji Konektivitas 2023",
    lampiran:
      "/storage/1692841288960KT_262_Keterangan_lulus_uji_konektivitas_SPARING_.pdf",
    created_time: 1692841298,
    status: true,
  },
  {
    _id: "6451c323273bb809343b8720",
    judul:
      "PENUNJUKAN LABORATORIUM UJI KINERJA ALAT PEMANTAUAN KUALITAS AIR OTOMATIS.",
    lampiran:
      "/storage/1683079968406Surat_Dit_PPA_Penyampaian_Informasi_Daftar_10_Lab.pdf",
    created_time: 1683079971,
    status: true,
  },
  {
    _id: "63dc9dd4f280733a28f207e3",
    judul: "MATERI BIMTEK SPARING  - 3 Februari 2023",
    lampiran: "/storage/1675402697250Materi_BIMTEK_SPARING_3_Februari_2023.pdf",
    created_time: 1675402708,
    status: true,
  },
  {
    _id: "63734852d8814a1e69c595bc",
    judul: "Surat Keterangan Lulus Uji Konektivitas 2022",
    lampiran: "/storage/KT.86 (Keterangan lulus uji konektivitas).pdf",
    created_time: 1668499538,
    status: true,
    updated_time: 1668675548,
  },
  {
    _id: "631ede5262d22b3284331557",
    judul:
      "Tata Laksana SPARING (Tahap Uji Konektivitas, Tahap Uji Kelaikan/Uji Validasi dan Tahap Pendaftaran)",
    lampiran: "/storage/TATA LAKSANA SPARING .pdf",
    created_time: 1662967378,
    status: true,
  },
  {
    _id: "62e2323ba9be4d01ab5e8474",
    judul: "Materi Bimbingan Teknis dan Coaching Clinic SPARING 28 Juli 2022",
    lampiran:
      "/storage/MATERI BIMTEK COACHING CLINIC PERATURAN SPARING 28 Juli 2022.pdf",
    created_time: 1658991163,
    status: true,
  },
  {
    _id: "62de3696a9be4d01ab2cc675",
    judul: "Undangan Bimbingan Teknis dan Coaching Clinic SPARING KLHK",
    lampiran:
      "/storage/UN.381 Bimtek dan coaching clinic SPARING 28 Jul 22.pdf",
    created_time: 1658730134,
    status: true,
  },
  {
    _id: "62cc119d0f1a8501ae316174",
    judul: "Surat Keterangan Lulus Uji Konektivitas Tahun 2020",
    lampiran:
      "/storage/(11) 2020-09-07T10_02_29.740ZSURAT KETERANGAN LULUS UJI KONEKTIVITAS TAHUN 2020.pdf",
    created_time: 1657541021,
    status: true,
    updated_time: 1673407846,
  },
  {
    _id: "62cc116c0f1a8501ae315233",
    judul:
      "Peraturan Menteri Lingkungan Hidup Nomor P.93/MENLHK/SETJEN/KUM.1/8/2018 tentang Pemantauan Kualitas Air Limbah Secara Terus Menerus Dan Dalam Jaringan Bagi Usaha Dan/Atau  Kegiatan jo Peraturan Menteri Lingkungan Hidup Nomor P.80/MENLHK/SETJEN/KUM.1/10/2019",
    lampiran: "/storage/(10) 2020-08-26T09_32_19.818ZMaster Buku Peraturan.pdf",
    created_time: 1657540972,
    status: true,
  },
  {
    _id: "62cc10210f1a8501ae30e2d9",
    judul: "Mekanisme Implementasi Dan Pusat Data Sparing 2021",
    lampiran:
      "/storage/(8) 2020-08-26T093626.475ZMEKANISME IMPLEMENTASI dan PUSAT DATA SPARING 2021 (c).pdf",
    created_time: 1657540641,
    status: true,
  },
  {
    _id: "62cc0ff60f1a8501ae30d697",
    judul: "Junkis Sparing 2021",
    lampiran: "/storage/(7) 2020-08-26T093626.475ZJUKNIS SPARING 2021 (c).pdf",
    created_time: 1657540598,
    status: true,
  },
  {
    _id: "62cc0fde0f1a8501ae30d1c1",
    judul: "Bimtek Peraturan SPARING 2021",
    lampiran:
      "/storage/(6) 2020-08-26T093626.475ZBIMTEK PERATURAN SPARING (c).pdf",
    created_time: 1657540574,
    status: true,
  },
  {
    _id: "62cc0f950f1a8501ae30bab4",
    judul: "Materi Peraturan SPARING PEMDA ",
    lampiran: "/storage/(5) Petunjuk Teknis SPARING_PEMDA2021T093626.475Z.pdf",
    created_time: 1657540501,
    status: true,
  },
  {
    _id: "62cc0f690f1a8501ae30ab90",
    judul: "Petunjuk Teknis Sparing ",
    lampiran: "/storage/(4)Petunjuk Teknis SPARING_2021T093626.475Z.pdf",
    created_time: 1657540457,
    status: true,
  },
  {
    _id: "62cc0f490f1a8501ae309748",
    judul: "Materi Bimtek Peraturan SPARING 2021- Citarum",
    lampiran:
      "/storage/(3) BIMTEK PERATURAN SPARING_2021T093626.475Z CITARUM-AF.pdf",
    created_time: 1657540425,
    status: true,
  },
  {
    _id: "62cc0edc0f1a8501ae303fd0",
    judul: "Surat keterangan Lulus Uji Konektivitas Tahun 2021",
    lampiran:
      "/storage/(2) SURAT KETERANGAN LULUS UJI KONEKTIVITAS TAHUN 2021.pdf",
    created_time: 1657540316,
    status: true,
    updated_time: 1658104235,
  },
  {
    _id: "62cc0ea70f1a8501ae301416",
    judul:
      "Pembahasan Komitmen Implementasi PERMENLHK NO.P.93 Tahun 2018 jo. P.80 Tahun 2019 tentang Pemantauan Kualitas Air Limbah Secara Terus Menerus & Dalam Jaringan bagi Usaha dan/atau Kegiatan (SPARING)",
    lampiran:
      "/storage/(1) 2021-12-30T093252.364ZMateri Penyampaian Komitmen Industri Wajib SPARING 27 Desember 2021.pdf",
    created_time: 1657540263,
    status: true,
    updated_time: 1658722492,
  },
];
