<template>
    <div class="content">
      <div>
      <div>
      <b-col
          cols="12"
          class="mb-3 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
          <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
          >
          <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
          </el-select>
      </b-col>
      
  
      <el-table
          :data="queriedData"
          row-key="id"
          header-row-class-name="thead-light"
          @sort-change="sortChange"
          @selection-change="selectionChange"
      >
          <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          v-bind="column"
          >
          </el-table-column>

          <el-table-column min-width="180px"  label="Aksi">
          <div slot-scope="action">
              <base-button
              @click="view(action.row)"
              type="primary"
              size="sm"
              icon
              >
              DOWNLOAD
              </base-button>
              <base-button
              type="warning"
              size="sm"
              icon
              >
              EDIT
              </base-button>
              <base-button
              type="danger"
              size="sm"
              icon
              >
              DELETE
              </base-button>
          </div>
          </el-table-column>
      </el-table>

      </div>
      <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
      <div class="">
          <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
  
          <span v-if="selectedRows.length">
              &nbsp; &nbsp; {{ selectedRows.length }} rows selected
          </span>
          </p>
      </div>
      <base-pagination
          class="pagination-no-border"
          :current="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          @change="paginationChanged($event)"
      >
      </base-pagination>
      </div>
      </div>
    </div>
  </template>
  <script>
  import { Table, TableColumn, Select, Option } from "element-ui";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import { BasePagination } from "@/components";
  import clientPaginationMixin from "../PaginatedTables/clientPaginationMixin";
  import users from "./dummyPublikasi"

  
  export default {
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      RouteBreadCrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data() {
      return {
        showAddPublicationModal: false,
        tableColumns: [
          {
            prop: "judul",
            label: "Judul",
            minWidth: 500,
          },
        ],
        tableData: users,
        selectedRows: [],
      };
    },
    methods: {
      paginationChanged(page) {
        this.pagination.currentPage = page;
      },
      view(get) {
          this.$router.push("/adminIndustriView");
          console.log("view");
      }
    },
  };
  </script>
  <style>
  .no-border-card .card-footer {
    border-top: 0;
  }
  </style>
  