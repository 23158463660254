<template>
    <div>
      <h1
        class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
        style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
      >
        <h6 class="h2 text-white d-inline-block mb-0">
          <i class="ni ni-single-02"></i> Industri
        </h6>
      </h1>

      <base-header class="pb-6">
      <b-row>
        <b-col xl="12" md="12">
          <b-card style="position: relative; overflow: hidden">
            <h3>Cari Berdasarkan</h3>

              <div class="d-flex justify-content-start" style="position: relative;z-index: 999;">
                <!-- status pendaftaran -->
                <div style="display: flex;flex-direction: column;width: 180px; margin-right: 10px;">
                  <label><small>Status Pendaftaran</small></label>
                  <el-select class="select-primary pagination-select" v-model="filterStatusPendaftaran"  placeholder="Status Pendaftaran">
                  <el-option
                      class="select-primary"
                      v-for="item in selectOptionStatus"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val"
                  >
                  </el-option>
                  </el-select>
                </div>

                <!-- Jenis Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Jenis Industri</small></label>
                  <base-input>
                    <el-select filterable placeholder="Jenis Industri" v-model="userInput.companyType" @change="resetIndustry()">
                      <el-option value="">Semua Jenis Industri</el-option>
                      <el-option
                        v-for="option, idx in provideSelect.companyType"
                        :key="idx"
                        :label="option.name"
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>

                <!-- Provinsi -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Provinsi</small></label>
                  <base-input name="Provinsi" >
                      <el-select
                        v-model="form.umum.compProvince"
                        @change="getCityDepentOnProvince(form.umum.compProvince)" filterable>
                        <el-option
                        label="Semua Provinsi"
                        :value=null
                        >
                        </el-option>
                        <el-option
                          v-for="option in provideSelect.provinsi"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota" >
                      <el-select
                        filterable
                        v-model="form.umum.compCity"
                        >
                        <el-option
                          v-for="option in provideSelect.kabupatenKota"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <!-- Nama Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" >
                  <label><small>Nama Industri</small></label>
                  <base-input name="Nama Industri" >
                      <el-select
                        filterable
                        v-model="userInput.companyName"
                        @change="getMyCompliencePoint"
                        :disabled="log_as !== 'admin'"
                        >
                        <el-option value="">Semua</el-option>
                        <el-option
                          v-for="option in provideSelect.companyName"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>
                
              </div>

            <base-button size="md" type="primary" @click="getBaseOnStatus">
              Tampilkan
            </base-button>
            <img
              src="img/brand/klhk.png"
              alt=""
              style="
                position: absolute;
                right: 0;
                top: -100px;
                z-index: 50;
                opacity: 0.1;
              "
            />
          </b-card>
        </b-col>
      </b-row>
      </base-header>
      
  
      <b-container fluid class="mt--6">
        <b-row>
          <b-col xl="12">
            <b-card>
              <template v-slot:header>
              </template>
              <b-row>
                <b-col lg="6" cols="7"></b-col>
                <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
                  <button
                    class="btn text-white btn-sm"
                    style="background-color: #0a7641"
                    @click="downloadExcelNow"
                  >
                    <i
                      class="fa fa-solid fa-file-excel mr-2"
                      style="font-size: 20px"
                    ></i>
                    Unduh Excel
                  </button>
                </b-col>
              </b-row>
              <!-- <PublikasiTable /> -->
              <el-table
                :data="listData"
                row-key="id"
                header-row-class-name="thead-light"
              >
              <el-table-column label="No" type="index" width="100" />
  
                <el-table-column min-width="180px" label="Nama">
                  <div slot-scope="action">
                    {{ action.row.name }}
                  </div>
                </el-table-column>
  
                <el-table-column min-width="180px" label="Jenis Perusahaan">
                  <div slot-scope="action">
                    {{ action.row.comp_type_name }}
                  </div>
                </el-table-column>

                <el-table-column min-width="180px" label="Provinsi">
                  <div slot-scope="action">
                    {{ action.row.province_name }}
                  </div>
                </el-table-column>

                <el-table-column min-width="180px" label="Telepon">
                  <div slot-scope="action">
                    {{ action.row.phone }}
                  </div>
                </el-table-column>

                <el-table-column min-width="180px" label="Status">
                  <div slot-scope="action">
                    <div :style="getStyleObject(action.row.is_verified)" class="text-white p-1 text-center rounded">
                      {{ action.row.is_verified }}
                    </div>
                  </div>
                </el-table-column>
  
  
                <el-table-column min-width="280px" label="Aksi">
                  <div slot-scope="action">
                    <base-button type="primary" size="sm" v-if="action.row.is_verified !== 'Diterima'" icon @click="validateCompany(action.row.id)">
                      VALIDASI
                    </base-button>
                    <base-button type="secondary" size="sm" icon @click="view(action.row.id)">
                      RINCIAN
                    </base-button>
                    <!-- <base-button type="warning" size="sm" icon @click="handleUpdate(action.row)">
                      EDIT
                    </base-button> -->
                    <base-button type="danger" size="sm" icon @click="handleDelete(action.row)" v-if="action.row.is_verified !== 'Menunggu Validasi'">
                      HAPUS
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>

              <div class="py-3">
                  <el-select
                    class="select-primary pagination-select mr-3"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    @change="changeSizePage()"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>

                  <small class="card-category">
                    Showing {{ 1 }} to {{ listData.length }} of
                    {{ pagination.total }} entries
                  </small>
                </div>

                <div style="display: flex; justify-content: flex-end">
                  <base-pagination
                    class="pagination-no-border"
                    :current="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                    @change="paginationChanged($event)"
                  >
                  </base-pagination>
                </div>

            </b-card>
          </b-col>
        </b-row>
      </b-container>
  
  
      <!-- Edit -->
      <Modal
        :show="showEditPublicationModal"
        modalContentClasses="mdl-content"
        modalClasses="modal-dialog-scrollable"
        headerClasses="pb-0"
        @close="showEditPublicationModal = false"
        size="lg"
      >
      <template v-slot:header>
          <div class="modal-title">
            <h4 class="mdl-title">Edit Role User</h4>
          </div>
        </template>
        <div class="px-4 border-top pt-4">
          <div class="form-group row">
            <div class="col-lg-12">
              <label class="col-form-label form-control-label">Name</label>
              <div>
                <input type="text" class="form-control" v-model="editnameInput" />
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-lg-12">
              <label class="col-form-label form-control-label">Description</label>
              <div>
                <input type="text" class="form-control" v-model="editdescriptionInput" />
              </div>
            </div>
          </div>
          
  
          
          <div class="form-group row">
            <button class="btn btn-primary" @click="doEdit">Update</button>
          </div>
        </div>
      </Modal>


      <!-- View -->
      <Modal
        :show="showModalView"
        modalContentClasses="mdl-content"
        modalClasses="modal-dialog-scrollable"
        headerClasses="pb-0"
        @close="showModalView = false"
        size="xl"
      >
      <template v-slot:header>
          <div class="modal-title">
            <h4 class="mdl-title">Rincian Perusahaan</h4>
          </div>
        </template>
        <div class="px-4 border-top pt-4">
         
          <ul class="list-group">
            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>Perusahaan</strong>
                </div>
                <div class="col-lg-9">
                  <span>{{ detailView.name }}</span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>Alamat</strong>
                </div>
                <div class="col-lg-9">
                  <span>{{ detailView.address }}</span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>Alamat Alternatif</strong>
                </div>
                <div class="col-lg-9">
                  <span>{{ detailView.address_alternative }}</span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>Jenis Perusahaan</strong>
                </div>
                <div class="col-lg-9">
                  <span>{{ detailView.company_type }}</span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>File Permohonan </strong>
                </div>
                <div class="col-lg-9">
                  <span>
                    <a :href="`${detailView.connection_request_file_path}`" target="blank">Lihat File</a>
                  </span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>Email</strong>
                </div>
                <div class="col-lg-9">
                  <span>{{ detailView.email }}</span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>Telp</strong>
                </div>
                <div class="col-lg-9">
                  <span>{{ detailView.phone }}</span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>Provinsi</strong>
                </div>
                <div class="col-lg-9">
                  <span>{{ detailView.province }}</span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>Kabupaten Kota</strong>
                </div>
                <div class="col-lg-9">
                  <span>{{ detailView.regency }}</span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>SIUP FILE</strong>
                </div>
                <div class="col-lg-9">
                  <span>
                    <a :href="`${detailView.siup_file_path}`" target="blank">Lihat File</a>
                  </span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>Nomor SIUP</strong>
                </div>
                <div class="col-lg-9">
                  <span>{{ detailView.siup_number }}</span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col-lg-3">
                  <strong>Penanggung Jawab SIUP</strong>
                </div>
                <div class="col-lg-9">
                  <span>{{ detailView.siup_on_behalf }}</span>
                </div>
              </div>
            </li>



          </ul>
          
  
          
          <div class="form-group row mt-4">
            <button class="btn btn-primary" @click="showModalView = false">TUTUP</button>
          </div>
        </div>
      </Modal>

        <!-- Loading Screen -->
    <Modal
      :show="isLoading"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="lg"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative">
                <img src="img/brand/img-logo-color.png" alt="" />
                <img
                  src="img/brand/klhk.png"
                  alt=""
                  style="
                    position: absolute;
                    right: 0;
                    top: -200px;
                    opacity: 0.2;
                  "
                />
                <h1>Menyiapkan Data...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Download Modal PDF -->
    <Modal
      :show="showDownloadModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModal = false"
      size="xl"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div class="p-4" style="height: 500px; overflow: scroll">
            <div id="printBos">
              <div v-if="isLoading" class="text-center" style="margin-top: 200px;"><h1>Sedang Menyiapkan Data ...</h1></div>
              <div v-else id="headerpdf">
                <div style="display: flex; justify-content: space-between">
                  <div>
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <div style="width: 50px; height: 50px">
                        <img
                          src="/img/brand/img-logo-color.png"
                          alt=""
                          style="width: 100%"
                        />
                      </div>
                      <h3 class="ml-3">Kementerian Lingkungan Hidup</h3>
                    </div>
                  </div>
                  <div>
                    <h2>{{ pdfName }}</h2>
                    <small><b>Laporan Perbulan</b></small
                    ><br />
                  </div>
                </div>
                <div v-if="listDataDownload.length == 0">
                  <h1 class="text-center">Tidak ada Data Laporan</h1>
                </div>
                <div v-else>
                  <table class="table mt-3">
                    <thead class="bg-primary">
                      <tr>
                        <th class="text-white">No</th>
                        <th class="text-white">Nama Industri</th>
                        <th class="text-white">Waktu</th>
                        <th class="text-white">PH</th>
                        <th class="text-white">COD (MG/L)</th>
                        <th class="text-white">TSS (MG/L)</th>
                        <th class="text-white">NH3N (MG/L)</th>
                        <th class="text-white">DEBIT (MG3/MENIT)</th>
                        <th class="text-white">Beban COD</th>
                        <th class="text-white">Beban TSS</th>
                        <th class="text-white">Beban NH3N</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, idx) in listDataDownload.items">
                        <td>{{idx + 1 }}</td>
                        <td>{{item.company_name }}</td>
                        <td>{{formatDate(item.timestamp) }}</td>
                        <td>{{item.ph ? item.ph.toFixed(5) : "Tidak Diwajibkan" }}</td>
                        <td>{{item.cod ? item.cod.toFixed(5) : "Tidak Diwajibkan"}}</td>
                        <td>{{item.tss ? item.tss.toFixed(5) : "Tidak Diwajibkan"}}</td>
                        <td>{{item.nh3n ? item.nh3n.toFixed(5) : "Tidak Diwajibkan"}}</td>
                        <td>{{item.debit? item.debit.toFixed(5): "Tidak Diwajibkan"}}</td>
                        <td>{{item.beban_cod? item.beban_cod.toFixed(5): "Tidak Diwajibkan"}}</td>
                        <td>{{item.beban_tss? item.beban_tss.toFixed(5): "Tidak Diwajibkan"}}</td>
                        <td>{{item.beban_nhn? item.beban_nhn.toFixed(5): "Tidak Diwajibkan"}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <button class="btn btn-primary" @click="print" :disabled="isLoading">Unduh PDF</button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Download Modal Excel -->
    <Modal
      :show="showDownloadModalCSV"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModalCSV = false"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4" style="height: 500px;overflow: scroll;">
            <div>
              <div>

                <div style="padding:10px;overflow-x: scroll;" >
                  <table class="table mt-3" ref="exportable_table">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-white">No</th>
                      <th class="text-white">Nama Industri</th>
                      <th class="text-white">Jenis Industri</th>
                      <th class="text-white">Kabupaten / Kota</th>
                      <th class="text-white">Provinsi</th>
                      <th class="text-white">Alamat</th>
                      <th class="text-white">Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in listDataDownload" :key="idx">
                      <td>{{ idx + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.company_type }}</td>
                      <td>{{ item.regency }}</td>
                      <td>{{ item.province }}</td>
                      <td>{{ item.address }}</td>
                      <td>{{ item.phone }}</td>
                    </tr>
                  </tbody>
                </table>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="ExportExcel('xlsx')"
            >
              Unduh Excel
            </base-button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModalCSV = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>
    </div>
</template>
  
  <script>
  import API from "../../../api/base_url";
  import baseURL from "../../../api/base_url_backend";
  import {Table, TableColumn, Select, Option } from "element-ui";
  import BaseHeader from "@/components/BaseHeader";
  import { BasePagination } from "@/components";
  import clientPaginationMixin from "../../Tables/PaginatedTables/clientPaginationMixin"
  import PublikasiTable from "../../Tables/AdminTable/Publikasi.vue";
  import Modal from "@/components/Modal.vue";
  import HtmlEditor from "@/components/Inputs/HtmlEditor";
  import { format } from "date-fns";
  import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

  // new
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
  
  
  export default {
    mixins: [clientPaginationMixin],
    components: {
      BaseHeader,
      BasePagination,
    flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      PublikasiTable,
      Modal,
      HtmlEditor,
    },
    mounted() {
      this.log_as = localStorage.getItem("as");
      this.getData()
      this.getCompanyType()
      this.getCompanyName()
      this.getProvince()
    },
    data() {
      return {
        selectOptionStatus: [
        {
          val:"",
          name:"Semua"
        },
        {
          val: "Menunggu Validasi",
          name:"Menunggu"
        },
        {
          val: "Diterima",
          name:"Disetujui"
        },
        {
          val: "Ditolak",
          name:"Ditolak"
        },
        ],
        // add Modal
        nameInput:"",
        descriptionInput:"",
        // edit Modal
        editnameInput:"",
        editdescriptionInput:"",
        editId:"",
        // others
        listData:[],
        showModalView: false,
        showAddPublicationModal: false,
        showEditPublicationModal: false,
        selectedRows: [],
        // category
        provideSelect: {
          question:[],
        },
        detailView: {
          address:"",
          address_alternative: "",
          company_type: "",
          company_type_id: "",
          connection_request_file_path: "",
          created_at: "",
          email: "",
          id: "",
          name: "",
          phone: "",
          province: "",
          province_id: "",
          regency: "",
          regency_id: "",
          siup_file_path: "",
          siup_number: "",
          siup_on_behalf: ""
        },
        pdfName: "",
        listDataDownload: [],
        listDataCompliencePoints: [],
        filterStatusPendaftaran: "",
      
      userInput: {
        tanggal: "",
        registrationType: "SP",
        titikPenaatan: "",
        companyType: "",
        companyName: "",
        provinsi: [],
        kabupatenKota: [],
        regency: [],
      },
      provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: false,
          time_24hr: true,
          plugins: [
            ShortcutButtonsPlugin({
              button: [
                {
                  label: "Data Hari ini",
                  attributes: {
                    class: "btn w-75 btn-dark mb-2 btn-sm",
                  },
                },
                {
                  label: "Data 1 Minggu",
                  attributes: {
                    class: "btn w-75 btn-dark mb-2 btn-sm",
                  },
                },
                {
                  label: "Data 1 Bulan",
                  attributes: {
                    class: "btn w-75 btn-dark mb-5 btn-sm",
                  },
                },
                {
                  label: "Reset Pencarian Tanggal",
                  attributes: {
                    class: "btn w-100 btn-danger btn-sm",
                  },
                },
              ],
              onClick: (index, fp) => {
                fp.clear();

                if (index == 0) {
                  const startDate = moment().startOf("day");
                  const endDate = moment(startDate).endOf("day");
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                } else if (index == 1) {
                  const startDate = moment().startOf("day");
                  const endDate = moment(startDate).add(1, "week").endOf("day");
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                } else if (index == 2) {
                  const startDate = moment().startOf("month");
                  const endDate = moment().endOf("month");
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                } else {
                  fp.clear();
                }
              },
            }),
          ], // Gunakan plugin di sini

          defaultHour: 0,
          mode: "range",
        },
        registrationType: [],
        compiencePoint: [],
        companyType: [],
        companyName: [],
        provinsi: [],
        kabupatenKota: [],
        regency: [],
      },
      form: {
        umum: {
          compName: null,
          compNameID: null,
          compType: null,
          compCity: "",
          compProvince: "",
        },
      },
      isLoading: false,
      showDownloadModal: false,
      showDownloadModalCSV: false,
      // end of filter
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1,
      },
      log_as: "",
      };
    },
    methods: {
      getStyleObject(val) {
        let backgroundColor;
        switch (val) {
          case "Menunggu Validasi":
            backgroundColor = "rgb(245 158 11)";
            break;
          case "Diterima":
            backgroundColor = "rgb(20 83 45)";
            break;
          case "Data Rusak":
            backgroundColor = "rgb(147 147 147)";
            break;
          case "Ditolak":
            backgroundColor = "#dc3545";
            break;
          default:
            backgroundColor = "white";
        }

        return {
          backgroundColor,
          // Anda dapat menambahkan properti CSS lainnya di sini
        };
      },
      validateCompany(id) {

        Swal.fire({
          title: "Validasi?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "TERIMA",
          denyButtonText: `TOLAK`,
          cancelButtonText: "BATAL",
        }).then((result) => {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }

          if (result.isConfirmed) {
            let payload = {
              is_verified: "Diterima"
            }

            API.put(`comps/${id}` , payload, {headers})
              .then(({data}) => {
                Swal.fire('Validasi Berhasil Diterima','Industri sudah di izinkan mengakses Sparing Platform','success')
                this.getData()
              })
              .catch((err) => {
                Swal.fire('Validasi Gagal', 'Gagal Menvalidasi Data Industri','error')
                this.isLoading = false
            })
          } else if (result.isDenied) {
            let payload = {
              is_verified: "Ditolak"
            }

            API.put(`comps/${id}` , payload, {headers})
              .then(({data}) => {
                Swal.fire('Validasi Berhasil Ditolak','Industri tidak di izinkan mengakses Sparing Platform','success')
                this.getData()
              })
              .catch((err) => {
                Swal.fire('Validasi Gagal', 'Gagal Menvalidasi Data Industri','error')
                this.isLoading = false
            })
          }
        });
      },
      getData() {
      this.isLoading = true
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
      API.get(`comps?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
        .then(({data}) => {
          if (data.data == null) {
              this.listData = []
            } else {
              this.listData = data.data
              this.pagination.total = data.meta.pagination.total_items
            }

            setTimeout(() => {
              this.isLoading = false
            }, 300);
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err);
        })
      },
      getCompanyType() {
      let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get('comp-types', {headers})
        .then(({data:content}) => {
          this.provideSelect.companyType = content.data
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getCompanyName() {
      let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get('comps', {headers})
        .then(({data:content}) => {
          this.provideSelect.companyName = content.data
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getProvince() {
      API.get(`provinces`)
        .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
      },
      getMyCompliencePoint() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`stations?comp_id=${this.userInput.companyName}` , {headers})
        .then(({data:content}) => {
          this.userInput.titikPenaatan = ""
          this.listDataCompliencePoints = content.data
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getCityDepentOnProvince(data) {
        this.form.umum.compCity = []
        this.provideSelect.kabupatenKota = [];
        API.get(`kabkots?prov_id=${data}`)
          .then(({ data: content }) => {
            this.provideSelect.kabupatenKota = content.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getBaseOnStatus() {
      this.isLoading = true

      let tmpDate = this.userInput.tanggal.split("to")

      let startDate = tmpDate[0]
      let endDate = tmpDate[1]


      if (endDate == undefined) {
        endDate = startDate
      } else if (endDate) {
        endDate.trim()
        startDate.trim()
      }

      const queryParams = this.queryParams();


      let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
      }

    
    

      const params = Object.fromEntries(
          Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
        );


      console.log(params)
      API.get(`comps` , { params, headers})
        .then(({data}) => {
          if (data.data == null) {
              this.listData = []
              this.isLoading = false
            } else {
              this.listData = data.data
              this.pagination.total = data.meta.pagination.total_items
              this.isLoading = false
            }
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err);
        })
      },
      queryParams(){
        let tmpDate = this.userInput.tanggal.split(" to ");

        let startDate = tmpDate[0];
        let endDate = tmpDate[1];

        if (endDate == undefined) {
          endDate = startDate;
        } else if (endDate) {
          endDate.trim();
          startDate.trim();
        }

        const queryParams = {
          page: this.pagination.currentPage,
          page_size: this.pagination.perPage,
          status: this.filterStatusPendaftaran,
          province_id: this.form.umum.compProvince,
          start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
          end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
          kabkot_id: this.form.umum.compCity,
          comp_id: this.userInput.companyName,
          comp_type_id: this.userInput.companyType,
          station_id: this.userInput.titikPenaatan,
        };
        return queryParams
      },
      getDataDownload() {
        this.isLoading = true

        let tmpDate = this.userInput.tanggal.split("to")

        let startDate = tmpDate[0]
        let endDate = tmpDate[1]


        if (endDate == undefined) {
          endDate = startDate
        } else if (endDate) {
          endDate.trim()
          startDate.trim()
        }


        let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        const queryParams = {
          is_export: true,
          status: this.filterStatusPendaftaran,
          province_id: this.form.umum.compProvince,
          start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
          end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
          kabkot_id: this.form.umum.compCity,
          comp_id: this.userInput.companyName,
          comp_type_id: this.userInput.companyType,
          station_id: this.userInput.titikPenaatan,
        };

        const params = Object.fromEntries(
            Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
          );


          API.get(`comps` , { params, headers})
            .then(({data}) => {
              if (data.data == null) {
                  this.listDataDownload = []
                  this.isLoading = false
                } else {
                  this.listDataDownload = data.data
                  this.isLoading = false
                }
            })
            .catch((err) => {
              this.isLoading = false
              console.log(err);
            })
      },
      
      // new
      downloadPDFNow() {
        if (!this.userInput.titikPenaatan) {
        return Swal.fire('Anda harus memilih titik penaatan','pilih titik penaatan yang ingin di unduh','error') 
        }

        this.getDataDownload();
        this.showDownloadModal = true;
      },
      downloadExcelNow() {
        if (!this.userInput.titikPenaatan) {
        return Swal.fire('Anda harus memilih titik penaatan','pilih titik penaatan yang ingin di unduh','error') 
        }
      
        this.getDataDownload();
        this.showDownloadModalCSV = true;
      },
      async print() {
        try {
          // Dapatkan data dari objek
          const data = this.listDataDownload;

          // Buat dokumen jsPDF
          const doc = new jsPDF();

          // Tambahkan header
          const header = [
            "No",
            "Nama Industri",
            "Waktu",
            "PH",
            "COD (MG/L)",
            "TSS (MG/L)",
            "NH3N (MG/L)",
            "DEBIT (MG3/MENIT)",
            "Beban COD",
            "Beban TSS",
            "Beban NH3N",
          ];

          let headerPemenuhan = [
                  "No",
                  "Pemantauan terus menerus dalam jaringan",
                  "Jumlah Data yang Memenuhi Baku Mutu Air Limbah",
                  "Presentase Pemenuhan Baku Mutu Air Limbah"
          ];
          let compName = ""
          let compAddress = ""
          let compLongitude = ""
          let compLatitude = ""
          let compTlp = ""
          let userEmail = ""
          let compType = ""
          let compSumberLimbah = ""
          let compTeknologi = ""
          let compNoIzin = ""
          let compInstansi = ""
          let compTglIzin = ""

          
          // Tambahkan baris data
          const rows = data.items.map((item, index) =>
            [
            index + 1,
            item.company_name,
            this.formatDate(item.timestamp),
            item.ph ? item.ph.toFixed(5) : "Tidak Diwajibkan",
            item.cod ? item.cod.toFixed(5) : "Tidak Diwajibkan",
            item.tss ? item.tss.toFixed(5) : "Tidak Diwajibkan",
            item.nh3n ? item.nh3n.toFixed(5) : "Tidak Diwajibkan",
            item.debit ? item.debit.toFixed(5) : "Tidak Diwajibkan",
            item.beban_cod ? item.beban_cod.toFixed(5) : "Tidak Diwajibkan",
            item.beban_tss ? item.beban_tss.toFixed(5) : "Tidak Diwajibkan",
            item.beban_nhn ? item.beban_nhn.toFixed(5) : "Tidak Diwajibkan",
          ]);

          // data.company.map((item,index)=>{
          //   compName = item.company_name
          //   compAddress = item.company_address
          //   compLongitude = item.bujur
          //   compLatitude = item.lintang
          //   compTlp = item.company_phone
          //   userEmail = item.company_email
          //   compType = item.company_type_name
          //   compSumberLimbah = item.sumber_air_limbah
          //   compTeknologi = item.jenis_teknologi_pengolahan_air_limbah
          //   compNoIzin = item.air_limbah_number
          //   compInstansi = item.pejabat_penerbit_izin
          //   compTglIzin = item.tanggal_penerbitan_izin
          // })

            compName = data.company.name
            compAddress = data.company.address
            compLongitude = "None"
            compLatitude = "None"
            compTlp = data.company.phone
            userEmail = data.company.email
            compType = data.company.company_type
            compSumberLimbah = data.sparing.sumber_air_limbah
            compTeknologi = data.sparing.jenis_teknologi_pengolahan_air_limbah
            compNoIzin = data.sparing_permit.air_limbah_number
            compInstansi = data.sparing_permit.pejabat_penerbit_izin
            compTglIzin = data.sparing_permit.tanggal_penerbitan_izin



          let bodyProfile = [
                  ["NAMA INDUSTRI", compName],
                  ["ALAMAT", compAddress],
                  ["LATITUDE", compLongitude],
                  ["LONGITUDE", compLatitude],
                  ["NOMOR TELEPON", compTlp],
                  ["EMAIL", userEmail],
                  ["JENIS USAHA", compType],
                  ["SUMBER LIMBAH", compSumberLimbah],
                  ["TEKNOLOGI PENGOLAHAN LIMBAH", compTeknologi],
                  ["NO. IZIN", compNoIzin],
                  ["INSTANSI PENERBIT", compInstansi],
                  ["TANGGAL TERBIT IZIN", compTglIzin]
          ];

          
          const logo = new Image();
          logo.src = '/img/brand/img-logo-color.png';

          // Tambahkan informasi profil di atas judul
          doc.addImage(logo, 'JPEG', 10, 10, 20, 20);
          doc.text("Kementerian Lingkungan Hidup", 35, 20, { lineHeight: 15 }); // Sesuaikan nilai lineHeight sesuai kebutuhan

          // Tambahkan judul
          doc.setFontSize(11);
          doc.text("Laporan Perbulan", 130, 20);
          doc.setTextColor(90, 90, 90)
          doc.setFont("bold");
          doc.text(`Data ini dibuat pada ${moment().format('DD-MM-YYYY HH:mm')}`, 130, 26);
          doc.setFont("normal");

        
          doc.autoTable({
            theme: "plain",
            body: bodyProfile,
            startY: 45, // Mulai di bawah judul profil
            columnStyles: {
              0: {
                fontStyle: "bold",
                halign: "left",
                cellWidth: 70,
                fillColor: [246, 249, 252],
              },
              1: { cellWidth: 100 },
            },
            styles: {
              cellPadding: 2,
              textColor: [41, 102, 75],
              // font: "times",
              lineWidth: 0.1,
              lineColor: [240, 240, 240],
              halign: "left",
            },
          });

          // Tambahkan tabel data di bawah informasi profil
          doc.autoTable({
            head: [header],
            theme: "striped",
            body: rows,
            headStyles: {
              fillColor: [41, 102, 75],
            },
            styles: {
              lineWidth: 0.2,
              halign: "center",
            },
          });

          doc.addPage();

            doc.autoTable({
                    head: [headerPemenuhan],
                    theme: "striped",
                    body: [['1','pH',3,100],['2','COD',3,100],['3','TSS',3,90],['4','NH3N',0,'Tidak Diwajibkan'],['5','Debit',3,100]],
                    headStyles: {
                      fillColor: [41, 102, 75]
                    },
                    styles: {
                      lineWidth: 0.2,
                      halign: "center"
                    },
            });

          // Simpan dokumen PDF
          doc.save("exportData.pdf");

          Swal.fire("Data di Unduh", "", "success");
          this.showDownloadModal = false;
        } catch (error) {
          console.error("Error:", error);
          // Tambahkan penanganan kesalahan jika diperlukan
        }
      },
      ExportExcel(type, fn, dl) {
        var elt = this.$refs.exportable_table;
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        Swal.fire("Data di Unduh", "", "success");
        this.showDownloadModalCSV = false;
        return dl
          ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
          : XLSX.writeFile(wb, fn || "SheetJSTableExport." + (type || "xlsx"));
      },
      htmlTableToExcel(type) {
          var data = document.getElementById('tblToExcl');
          var excelFile = XLSX.utils.table_to_book(data, { sheet: "sheet1" });
          XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
          XLSX.writeFile(excelFile, 'ExportedFile:HTMLTableToExcel.' + type);
      },
    
    reportTableState() {
      return [];
    },
      
      handleUpdate(row) {

      },
      doEdit() {

      },
      handleDelete(row) {
        Swal.fire({
          title: 'Hapus Data ini ? ',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'HAPUS',
          cancelButtonText: 'BATAL',
        }).then((result) => {
          if (result.isConfirmed) {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
            API.delete(`companies/${row.id}` , {headers})
            .then(({data:content}) => {
              Swal.fire('DIHAPUS','','success')
              this.getData()
            })
            .catch((err) => {
              Swal.fire('Gagal Menghapus','','error')
              console.log(err);
            })
          }
        })
      },
      view(id) {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        API.get(`comps/${id}`, {headers})
              .then(({data}) => {
                let row = data.data
                this.detailView = {
                    address: row.address,
                    address_alternative: row.street,
                    company_type: row.comp_type_name,
                    company_type_id: row.company_type_id,
                    connection_request_file_path: (row.koneksi_file_id ? `${baseURL}blobs/download/${row.koneksi_file_id}` :""),
                    created_at: row.created_at,
                    email: row.email,
                    id: row.id,
                    name: row.name,
                    phone: row.phone,
                    province: row.province_name,
                    province_id: row.province_id,
                    regency: row.kabkot_name,
                    regency_id: row.regency_id,
                    siup_file_path: (row.siup_file_id ? `${baseURL}blobs/download/${row.siup_file_id}` :""),
                    siup_number: row.siup_no,
                    siup_on_behalf: row.siup_pj
                }
              })
              .catch((err) => {
                Swal.fire('Validasi Gagal', 'Gagal Menvalidasi Data Industri','error')
                this.isLoading = false
        })
       

        this.showModalView = true
      },
      changeSizePage() {
      this.getData();
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.getData();
    },
    },
  };
  </script>
  
  